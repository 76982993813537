import * as React from "react";
import Layout from "../components/Layout";
import PackagesShow from "../components/PackagesShow";
import Seo from "../components/Seo";

const Packages = () => {
  return (
    <Layout>
      <Seo title="Packages" />
      <PackagesShow />
    </Layout>
  );
};

export default Packages;
