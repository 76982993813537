import React from "react";
import styled from "styled-components";
import Wrapper from "./styles/Wrapper";
import { StaticImage } from "gatsby-plugin-image";
import Button from "../components/styles/Button";

const PackagesStyle = styled.div`
  margin: 0 64px;

  @media (max-width: 900px) {
    margin: 0 20px;
  }

  h1 {
    font-size: 2.5rem;
    color: var(--dark-gray);
    text-align: center;
    margin-bottom: 50px;
    font-weight: 700;
  }

  h2 {
    text-align: center;
  }
`;

const PackageCardHolder = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  h2 {
    font-size: 2rem;
    color: var(--dark-gray);
  }
`;

const PackageCard = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border: 1px solid rgba(172, 172, 172, 0.1);
  box-shadow: 0 0 30px rgb(0 0 0 / 10%);
  margin-bottom: 20px;
  width: 270px;

  @media (max-width: 800px) {
    flex-wrap: wrap;
    width: 300px;
  }
  height: 100%;
`;

const PackageImage = styled.div`
  width: auto;
`;

const PackageInfo = styled.div`
  margin: 10px 15px 15px 15px;
  height: 300px;

  ul {
    list-style-position: inside;
    padding-left: 0;
    font-size: 1rem;
    font-weight: 200;
    line-height: 2rem;
  }

  .card-title {
    display: flex;
    h3 {
      margin: 0;
      font-size: 1.2rem;
      @media (max-width: 800px) {
        font-size: 1.2rem;
      }
    }

    h3.price {
      color: var(--dark-pink);
    }

    justify-content: space-between;
  }
`;

const ButtonHolder = styled.div`
  /* background-color: red; */
  height: 50px;
  text-align: center;
`;

const ButtonReserve = styled(Button)`
  padding: 5px 40px;
  text-align: center;
  align-items: center;
`;

const PackagesShow = () => {
  return (
    <Wrapper>
      <PackagesStyle>
        <h1>Pricing and Packages</h1>
        <ul>
          <li>All pricing is before tax and delivery</li>
          <li>
            There is a set up fee applied when setting up over grass, gravel or
            sand.
          </li>
          <li>All packages are for up to 4 hours of play time</li>
          <li>$50 deposit fee required to secure your date</li>
        </ul>
        <h2>Soft Play Zones</h2>
        <PackageCardHolder>
          <PackageCard>
            <PackageImage>
              <StaticImage src="../assets/images/tiny_zone_rainbow.jpg" />
            </PackageImage>
            <PackageInfo>
              <div className="card-title">
                <h3>Tiny Tots Zone</h3>
                <h3 className="price">$200</h3>
              </div>
              <div>
                <ul>
                  <li>Toddler Bounce</li>
                  <li>Soft play set</li>
                  <li>2 animal hoppers</li>
                  <li>Soft building blocks</li>
                  <li>Soft foam tile flooring</li>
                  <li>Fence upon request (add +$30)</li>
                </ul>
              </div>
            </PackageInfo>
            <ButtonHolder></ButtonHolder>
          </PackageCard>
          <PackageCard>
            <PackageImage>
              <StaticImage src="../assets/images/softplay-pink-ballpit.jpg" />
            </PackageImage>
            <PackageInfo>
              <div className="card-title">
                <h3>Little Explorers' Zone</h3>
                <h3 className="price">$350</h3>
              </div>
              <div>
                <ul>
                  <li>8ft x 14ft play area</li>
                  <li>Tiny Tots Ball Pit (6x6ft) </li>
                  <li>Soft play set</li>
                  <li>2 animal hoppers</li>
                  <li>Soft building blocks</li>
                  <li>Soft foam tile flooring</li>
                  <li>Fence (optional)</li>
                </ul>
              </div>
            </PackageInfo>
            <ButtonHolder></ButtonHolder>
          </PackageCard>
          <PackageCard>
            <PackageImage>
              <StaticImage src="../assets/images/unicorn-softplay-adventure.jpg" />
            </PackageImage>
            <PackageInfo>
              <div className="card-title">
                <h3>Adventure Zone</h3>
                <h3 className="price">$400</h3>
              </div>
              <div>
                <ul>
                  <li>Toddler Bounce</li>
                  <li>Tiny Tots Ball Pit (6'x5')</li>
                  <li>Soft play set</li>
                  <li>2 animal hoppers</li>
                  <li>Soft building blocks</li>
                  <li>Soft foam tile flooring</li>
                  <li>Fence (optional)</li>
                </ul>
              </div>
            </PackageInfo>
            <ButtonHolder></ButtonHolder>
          </PackageCard>
          <PackageCard>
            <PackageImage>
              <StaticImage src="../assets/images/ultimate_zone_bounce_softplay_ballpit.jpg" />
            </PackageImage>
            <PackageInfo>
              <div className="card-title">
                <h3>Luxe Tots Zone</h3>
                <h3 className="price">$600</h3>
              </div>
              <div>
                <ul>
                  <li>White Bounce House</li>
                  <li>Tiny Tots Ball Pit (6'x 5')</li>
                  <li>Soft play equipment</li>
                  <li>Soft foam tile flooring</li>
                  <li>Fence (optional)</li>
                </ul>
                <ul>
                  Add-ons
                  <li>Custom decals available upon request.</li>
                </ul>
              </div>
            </PackageInfo>
            <ButtonHolder></ButtonHolder>
          </PackageCard>
          <PackageCard>
            <PackageImage>
              <StaticImage src="../assets/images/luxury_zone_bounce_ballpit.jpg" />
            </PackageImage>
            <PackageInfo>
              <div className="card-title">
                <h3>Luxury Zone</h3>
                <h3 className="price">$650</h3>
              </div>
              <div>
                <ul>
                  <li>White Bounce House</li>
                  <li>Premium Ball Pit (8x8ft)</li>
                  <li>Slide for the ball pit</li>
                  <li>Soft foam tile flooring</li>
                </ul>
                <ul>
                  Add-ons
                  <li>Custom decals available upon request.</li>
                  <li>Luxe white slide</li>
                </ul>
              </div>
            </PackageInfo>
            <ButtonHolder></ButtonHolder>
          </PackageCard>
        </PackageCardHolder>
        <h2>Ball Pits</h2>
        <PackageCardHolder>
          <PackageCard>
            <PackageImage>
              <StaticImage src="../assets/images/small-ballpit-yellow.jpg" />
            </PackageImage>
            <PackageInfo>
              <div className="card-title">
                <h3>Tiny Tots Ball Pit</h3>
                <h3 className="price">$200</h3>
              </div>
              <div>
                <ul>
                  <li>6x5ft (1500 balls) </li>
                  <li>Soft foam tile flooring</li>
                </ul>
                <ul>
                  Add-ons
                  <li>Custom decals available upon request.</li>
                </ul>
              </div>
            </PackageInfo>
            <ButtonHolder></ButtonHolder>
          </PackageCard>
          <PackageCard>
            <PackageImage>
              <StaticImage src="../assets/images/medium_ballpit.jpg" />
            </PackageImage>
            <PackageInfo>
              <div className="card-title">
                <h3>Medium Ball Pit</h3>
                <h3 className="price">$300</h3>
              </div>
              <div>
                <ul>
                  <li>6x7ft (2500 balls) </li>
                  <li>Slide (light pink/ light blue)</li>
                  <li>Soft foam tile flooring</li>
                </ul>
                <ul>
                  Add-ons
                  <li>Luxury white slides upon request</li>
                </ul>
              </div>
            </PackageInfo>
            <ButtonHolder></ButtonHolder>
          </PackageCard>
          <PackageCard>
            <PackageImage>
              <StaticImage src="../assets/images/premium-ballpit-pink.jpg" />
            </PackageImage>
            <PackageInfo>
              <div className="card-title">
                <h3>Premium Ball Pit</h3>
                <h3 className="price">$400</h3>
              </div>
              <div>
                <ul>
                  <li>8x8ft (3500 balls) </li>
                  <li>Slide (light pink/ light blue)</li>
                  <li>Soft foam tile flooring</li>
                </ul>
                <ul>
                  Add-ons
                  <li>Luxury white slides upon request</li>
                </ul>
              </div>
            </PackageInfo>
            <ButtonHolder></ButtonHolder>
          </PackageCard>
          <PackageCard>
            <PackageImage>
              <StaticImage src="../assets/images/royal-ballpit.jpg" />
            </PackageImage>
            <PackageInfo>
              <div className="card-title">
                <h3>Royal Ball Pit</h3>
                <h3 className="price">$700</h3>
              </div>
              <div>
                <ul>
                  <li>8x13ft (7000 balls) </li>
                  <li>Soft foam tile flooring</li>
                </ul>
                <ul>
                  Add-ons
                  <li>Luxury double white slides and banner upon request</li>
                </ul>
              </div>
            </PackageInfo>
            <ButtonHolder></ButtonHolder>
          </PackageCard>
        </PackageCardHolder>
        <h2>White Bounce Houses</h2>
        <PackageCardHolder>
          <PackageCard>
            <PackageImage>
              <StaticImage src="../assets/images/boho-bounce-greenballoons.jpg" />
            </PackageImage>
            <PackageInfo>
              <div className="card-title">
                <h3>Boho Bounce House</h3>
                <h3 className="price">$300</h3>
              </div>
              <div>
                <ul>
                  <li>Size: 12ft W 15ft L 15ft H </li>
                  <li>Requires a 16ft x 18ft space</li>
                </ul>
              </div>
            </PackageInfo>
            <ButtonHolder></ButtonHolder>
          </PackageCard>
          <PackageCard>
            <PackageImage>
              <StaticImage src="../assets/images/castle-bounce.jpg" />
            </PackageImage>
            <PackageInfo>
              <div className="card-title">
                <h3>Classic White Castle</h3>
                <h3 className="price">$300</h3>
              </div>
              <div>
                <ul>
                  <li>Size: 14ft W 15ft L 15ft H </li>
                  <li>Requires a 18ft x 18ft space</li>
                </ul>
              </div>
            </PackageInfo>
            <ButtonHolder></ButtonHolder>
          </PackageCard>
        </PackageCardHolder>
      </PackagesStyle>
    </Wrapper>
  );
};
export default PackagesShow;
